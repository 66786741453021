<template>
    <div class="tgcss-w-full tgcss-pb-2 tgcss-px-1 font-IRANSans">

        <!-- <div class="tgcss-w-full tgcss-flex tgcss-items-center tgcss-mb-1">
            <span class="tgcss-text-sm tgcss-font-bold grow">{{title}}</span> 
        </div> -->
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>
        <div v-if="!loading && category" class="tgcss-flex tgcss-overflow-x-auto tgcss-flex-nowrap tgcss-py-2 tgcss-pb-3"
        :class="{ ' tgcss-grid tgcss-grid-cols-3  tgcss-gap-4 ' : grid == true }"
        >
            <div v-for="item in category" :key="item.id">


                <router-link v-if="item.type == 'category'"  :to="{ name: 'CategoryNews' , params: { category_id: item.id, }, query: { subject: item.id }}" class="tgcss-cursor-pointer tgcss-flex tgcss-items-center tgcss-min-w-24 !tgcss-no-underline tgcss-font-semibold hover:tgcss-bg-gray-100 !tgcss-text-gray-700 tgcss-flex-col tgcss-justify-center tgcss-rounded-md tgcss-overflow-hidden tgcss-w-fit  tgcss-border-2 tgcss-border-gray-200 tgcss-ml-2 tgcss-py-3 tgcss-px-4  tgcss-min-w-28">
                    <div class="tgcss-flex tgcss-items-center tgcss-justify-center tgcss-overflow-hidden tgcss-h-[45px] tgcss-w-[45px] tgcss-mb-2">
                        <img class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" :src="item.icon" :alt="item.name">
                    </div>
                    <div class="tgcss-text-nowrap tgcss-px-2">{{item.name}}</div>
                </router-link>

                <router-link v-if="item.type == 'category-main'"  :to="{ name: 'ApplicationNews' , params: { category_id: item.id, }, query: { subject: item.name }}" class="tgcss-cursor-pointer tgcss-flex tgcss-items-center tgcss-min-w-24 !tgcss-no-underline tgcss-font-semibold hover:tgcss-bg-gray-100 !tgcss-text-gray-700 tgcss-flex-col tgcss-justify-center tgcss-rounded-md tgcss-overflow-hidden tgcss-w-fit  tgcss-border-2 tgcss-border-gray-200 tgcss-ml-2 tgcss-py-3 tgcss-px-4 tgcss-min-w-28">
                    <div class="tgcss-flex tgcss-items-center tgcss-justify-center tgcss-overflow-hidden tgcss-h-[45px] tgcss-w-[45px] tgcss-mb-2">
                        <img class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" :src="item.icon" :alt="item.name">
                    </div>
                    <div class="tgcss-text-nowrap tgcss-px-2">{{item.name}}</div>
                </router-link>

                
                <router-link v-if="item.type == 'tag'"  :to="{ name: 'ApplicationNews' , params: { category_id: item.slug, }, query: { subject: item.name }}" class="tgcss-cursor-pointer tgcss-flex tgcss-items-center tgcss-min-w-24 !tgcss-no-underline tgcss-font-semibold hover:tgcss-bg-gray-100 !tgcss-text-gray-700 tgcss-flex-col tgcss-justify-center tgcss-rounded-md tgcss-overflow-hidden tgcss-w-fit  tgcss-border-2 tgcss-border-gray-200 tgcss-ml-2 tgcss-py-3 tgcss-px-4 tgcss-min-w-28">
                    <div class="tgcss-flex tgcss-items-center tgcss-justify-center tgcss-overflow-hidden tgcss-h-[45px] tgcss-w-[45px] tgcss-mb-2">
                        <img class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" :src="item.icon" :alt="item.name">
                    </div>
                    <div class="tgcss-text-nowrap tgcss-px-2">{{item.name}}</div>
                </router-link>

                
                <router-link v-if="item.type == 'page'"  :to="{ name: 'CategoryNews' , params: { category_id: item.id, }, query: { subject: 'tgju-news-' + item.id }}" class="tgcss-cursor-pointer tgcss-flex tgcss-items-center tgcss-min-w-24 !tgcss-no-underline tgcss-font-semibold hover:tgcss-bg-gray-100 !tgcss-text-gray-700 tgcss-flex-col tgcss-justify-center tgcss-rounded-md tgcss-overflow-hidden tgcss-w-fit  tgcss-border-2 tgcss-border-gray-200 tgcss-ml-2 tgcss-py-3 tgcss-px-4 tgcss-min-w-28">
                    <div class="tgcss-flex tgcss-items-center tgcss-justify-center tgcss-overflow-hidden tgcss-h-[45px] tgcss-w-[45px] tgcss-mb-2">
                        <img class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" :src="item.icon" :alt="item.name">
                    </div>
                    <div class="tgcss-text-nowrap tgcss-px-2">{{item.name}}</div>
                </router-link>

            </div>
        </div>


    </div>
</template>
    
<script>
    export default {
        name: 'newsRow',
        props: ['cat', 'count', 'title', 'type','grid'],
        components: {},
        data() {
            return {
                categories:[],
                loading:false,
                category:[
                    {
                        id:100001,
                        type:'tag',
                        name:'اخبار ویژه',
                        slug:'اخبار-ویژه',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/%D9%88%DB%8C%DA%98%D9%87.svg?021',
                    },
                    // {
                    //     id:100002,
                    //     type:'page',
                    //     name:'دیده‌بان بازار',
                    //     slug:'live',
                    //     icon:'https://static.tgju.org/views/default/images/tgju/app/news/دیده‌بان بازار.svg',
                    // },
                    {
                        id:100003,
                        type:'category',
                        name:'دسته‌های خبری',
                        slug:'دسته-های-خبری',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/دسته_های خبری.svg?02',
                    },
                    {
                        id:100004,
                        type:'tag',
                        name:'تحلیل بنیادین',
                        slug:'تحلیل-بنیادین',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/%D8%AA%D8%AD%D9%84%DB%8C%D9%84%20%D8%A8%D9%86%DB%8C%D8%A7%D8%AF%DB%8C%D9%86.svg?01',
                    },
                    {
                        id:100005,
                        type:'category',
                        name:'تحلیل تکنیکال',
                        slug:'تحلیل-تکنیکال',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/%D8%AA%D8%AD%D9%84%DB%8C%D9%84%20%D8%AA%DA%A9%D9%86%DB%8C%DA%A9%D8%A7%D9%84.svg?01',
                    },
                    {
                        id:84008,
                        type:'category-main',
                        name:'گزارش‌ بازارها',
                        slug:'گزارش-بازارها',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/%DA%AF%D8%B2%D8%A7%D8%B1%D8%B4%20%D8%A8%D8%A7%D8%B2%D8%A7%D8%B1%D9%87%D8%A7.svg?401',
                    },
                    {
                        id:100007,
                        type:'category',
                        name:'آکادمی',
                        slug:'آکادمی',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/%D8%A2%DA%A9%D8%A7%D8%AF%D9%85%DB%8C.svg?0241',
                    },
                    {
                        id:100008,
                        type:'category',
                        name:'دانش نامه',
                        slug:'دانش-نامه',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/دانش نامه.svg?01',
                    },
                    {
                        id:100009,
                        type:'category',
                        name:'بروکرها',
                        slug:'بروکرهای-فارکس',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/بروکرها.svg?01',
                    },
                    {
                        id:100010,
                        type:'category',
                        name:'ویکی‌کریپتو',
                        slug:'ویکی-کریپتو',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/ویکی_کریپتو.svg?00401',
                    },
                    // {
                    //     id:100011,
                    //     type:'page',
                    //     name:'مفاهیم‌،اصطلاحات',
                    //     slug:'glossary',
                    //     icon:'https://static.tgju.org/views/default/images/tgju/app/news/مفاهیم‌،اصطلاحات.svg',
                    // },
                    {
                        id:100012,
                        type:'category',
                        name:'وبلاگ',
                        slug:'مشاور-خرید',
                        icon:'https://static.tgju.org/views/default/images/tgju/app/news/blue1/وبلاگ.svg?0052',
                        grid:'span-2'
                    },
                    // {
                    //     id:100013,
                    //     type:'category-main',
                    //     name:'مولتی‌مدیا',
                    //     slug:'مولتی-مدیا',
                    //     icon:'https://static.tgju.org/views/default/images/tgju/app/news/مولتی‌مدیا.svg',
                    // },
                ]
            }
        },
        mounted() {
            this.getData();

        },
        computed: {},
        methods: {
            isUpdatedLessThan24HoursAgo(updatedAt) {
                const updatedTimestamp = new Date(updatedAt).getTime() / 1000; // تبدیل تاریخ به تایم‌استمپ Unix
                const currentTimestamp = Date.now() / 1000; // تایم‌استمپ فعلی به ثانیه
                const oneDayInSeconds = 43200; // تعداد ثانیه‌ها در یک روز (24 ساعت)

                // بررسی اینکه آیا تاریخ وارد شده کمتر از 24 ساعت از تاریخ فعلی گذشته است
                return updatedTimestamp > currentTimestamp - oneDayInSeconds;
            },
            getData(){
                this.loading = true;
                $.ajax({
                    url: ( this.type == 'slug' ? 'https://api.tgju.org/v1/news/list?limit='+this.count+'&tag='+this.cat : 'https://api.tgju.org/v1/news/list?limit='+this.count+'&category_id='+this.cat ),
                    method: 'get',
                    complete: (res) => {
                        this.categories = res.responseJSON.response;  
                        this.loading = false;
                    }
                });
            },
        },
    }
</script>  